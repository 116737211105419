import { useEffect, useState, FC } from 'react'
import { useNavigate } from "react-router-dom";
import css from './index.module.css';
import Content from '@components/adminNavigation';
import axios from "axios";
import * as Types from '../../../types';
import Popup from '@components/popup';
import SEO from '@components/seo';

export default function Products() {
  const navigate = useNavigate();

  const [addProduct, setAddProduct] = useState(false);
  const [products, setProducts] = useState<Types.Product[]>([]);

  useEffect(() => {
    axios.get(`${import.meta.env.VITE_API_ADM_URL}/products`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      }
    }).then(response => {
      setProducts(response.data);
    }).catch(error => {
      console.log(error);
    });
  }, []);

  return (
    <Content>
      <SEO 
        title={'Управление продуктами'}
        description={'Админ-панель Sweet Cat Shop'}
        ogUrl={`https://sweetcatshop.ru/`}
      />

      <CreatePopup showPopup={addProduct} setShowPopup={setAddProduct} />
      <h1 className={css.head}>Список товаров <i className="fi fi-rr-add" style={{ float: 'right' }} onClick={() => setAddProduct(true)} /></h1>

      <div className={css.table}>
        <div className={css.tableHeader}>
          <a>Категория</a>
          <a>Название</a>
          <a>Бренд</a>
          <a>Цена</a>
        </div>

        <div className={css.tableBody}>
          {products.map((item) => (
            <div className={css.tableDiv} onClick={() => navigate(`/manage/product/${item.id}`)}>
              <a>{typeof item.category === 'object' && 'name' in item.category ? item.category.name : "Неизвестно"}</a>
              <a>{item.name}</a>
              <a>{item.brand}</a>
              <a>{item.price} ₽</a>
            </div>
          ))}
        </div>
    </div>
    </Content>
  );
}

interface PopupInfo {
  showPopup: boolean, 
  setShowPopup: any
}

const CreatePopup: FC<PopupInfo> = ({ showPopup, setShowPopup }) => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState<Types.Category[]>();

  useEffect(() => {
    axios.get(`${import.meta.env.VITE_API_ADM_URL}/categories`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      }
    }).then(response => {
      setCategories(response.data);
    }).catch(error => {
      console.log(error);
    });
  }, []);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    axios.post(`${import.meta.env.VITE_API_ADM_URL}/products`, formData, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        }
    })
    .then(response => {
      navigate(`/manage/product/${response.data.id}`)
    })
    .catch(error => {
      console.log(error);
    });
  };
  
  return (
  <Popup showPopup={showPopup} setShowPopup={setShowPopup}>
      <h2>Добавить продукт</h2>

      <form onSubmit={handleSubmit}>
        <div className={css.inputGrid}>
          <label>Категория</label>
          <select name='category'>
            {categories?.map(item => (
                <option value={item.id}>{item.name}</option>
            ))}
          </select>
        </div>
              
        <div className={css.inputGrid}>
          <label>Название</label>
          <input name='name' type='text' placeholder='Название' />
        </div>

        <div className={css.inputGrid}>
          <label>Бренд</label>
          <input name='brand' type='text' placeholder='Бренд' />
        </div>

        <div className={css.inputGrid}>
          <label>Цена</label>
          <input name='price' type='number' placeholder='Цена' />
        </div>

        <button className={css.saveBtn} type='submit'>Добавить новый продукт</button>
      </form>
  </Popup>
  )
}