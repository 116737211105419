import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from 'axios';
import css from './index.module.css';

import SEO from "@components/seo";
import MainContent from "@components/content";
import CartProduct from "@components/cartProduct";

import useUserData from "@hooks/useUserData";
import * as Types from '../../types';
import { statusText, statusDesc } from './order.info';

import MiniChat from "@components/chat";
import ReviewPopup from "@components/reviewPopup";

export default function Order() {
  const { id } = useParams();
  const { siteData } = useUserData();

  const [showPopupReview, setShowPopupReview] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [order, setOrder] = useState<Types.Order>();

  useEffect(() => {
    axios.get(`${import.meta.env.VITE_API_URL}/user/order/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      }
    })
    .then(response => {
      setOrder(response.data);
    })
    .catch(error => {
      console.log(error);
    });
  }, [id]);

  return (
    <MainContent cover={false} width={1400}>    
      <ReviewPopup showPopup={showPopupReview} setShowPopup={setShowPopupReview} id={id!}/>
      <MiniChat showPopup={showChat} setShowPopup={setShowChat}/>

      <SEO 
        title="Заказ"
        description="Управление заказом"
        ogUrl="https://sweetcatshop.ru/"
      />

      <div className={css.order}>
          <div className={css.col1}>
            <div className={css.summaryInfo}>
              <a>заказ №{order?.id}</a>
              <b>{statusText[order?.status!]}</b>
              <a>{statusDesc[order?.status!]}</a>
            </div>

            {order?.products.map((item: Types.CartData) => (
              <CartProduct 
                id={item.id}
                prodID={item.product.id}
                image={item.product.images[0]}
                title={item.product.name}
                desc={<div className={css.info}><div className={css.color} style={{ color: item.product.colors.find(i => i.id === item.params.color)?.color }} /> {item.product.sizes.find(i => i.id === item.params.size)?.name}</div>}
                price={item.product.price}
                width={'100%'}
              />
            ))}
          </div>

          <div className={css.col2} style={{ flex: 0.33 }}>
            <div className={css.orderInfo}>
                {order?.promocode &&
                  <div className={css.textOrderInfo}>
                    <a>промокод</a>
                    <b>{order?.promocode} </b>
                  </div>
                }

                <div className={css.textOrderInfo}>
                  <a>стоимость доставки</a>
                  <b>{order?.delivery.deliveryPrice} ₽ </b>
                </div>

                <div className={css.textOrderInfo}>
                  <a>адрес</a>
                  <b>{order?.delivery.address} ₽ </b>
                </div>

                <div className={css.textOrderInfo}>
                  <b style={{ fontSize: 20 }}>итого</b>
                  <b style={{ fontSize: 18 }}>{order?.totalPrice} ₽ </b>
                </div>

                {order?.status! >= 1 && order?.status! < 5 &&
                  <div className={css.cardOrder}>
                    <a>заказ оплачен картой</a>
                  </div>
                }

                {order?.status === 0 &&
                  <a className={css.reviewOrder} href={`https://yoomoney.ru/payments/external/confirmation?orderId=${order.kassaID}`}>оплатить заказ</a>
                }

                {order?.status === 4 && order?.review.stars <= 0 &&
                  <a className={css.reviewOrder} onClick={() => setShowPopupReview(true)}>оцените заказ</a>
                }

                {order?.status === 5 && order?.review.stars <= 0 &&
                  <a className={css.reviewOrder} onClick={() => setShowPopupReview(true)}>что пошло не так?</a>
                }
            </div>

            <div className={css.orderActions}>
                <div className={css.orderAction} onClick={() => setShowChat(true)}>
                  <a>помощь</a>
                </div>

                <div className={css.orderAction}>
                  <a>вернуть заказ</a>
                </div>
            </div>
            
            <div className={css.orderDocs}>
              <a href={siteData.terms}>Политика возврата</a>
              <a href={siteData.privacy}>Политика конфиденциальности</a>
            </div>
          </div>
      </div>
    </MainContent>
  )
}