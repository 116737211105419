import { FC } from 'react';
import { useNavigate } from "react-router-dom";
import css from './index.module.css';

interface MiniProduct {
    id: number;
    title: string;
    price: number; 
    image: string;
}

const MiniProduct: FC<MiniProduct> = ({ id, title, price, image }) => {
    const navigate = useNavigate();

    const goProduct = () => {
        navigate(`/product/${id}`);
    };

    return (
        <div className={css.miniProduct} onClick={goProduct}>
            <img src={image} alt={title} />
            <div className={css.textInfo}>
                <h2>{title}</h2>
                <a>{price} ₽</a>
            </div>
        </div>
    );
};

export default MiniProduct;