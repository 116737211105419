import { Link } from "react-router-dom";
import css from './index.module.css';
import SEO from "@components/seo";
import Cate from '@assets/images/cate.png';
import useUserData from "@hooks/useUserData";

export default function NotFound () {
    const { siteData } = useUserData();

  return (
    <div className={css.container}>
        <SEO 
            title="Страница не найдена"
            description="Sweet Cat Shop"
            ogUrl="https://sweetcatshop.ru/"
        />

        <div className={css.component}>        
            <div>
                <h1>потерялись?</h1>
                <a>такой страницы не существует, давайте вернемся на главную</a>

                <div className={css.back}><Link to="/">вернуться на главную</Link></div>
            </div>

            <div>
                <img src={Cate} alt="Logo" />
                <div className={css.legal}>
                    <a href={siteData.terms}>Лицензионное соглашение</a>
                    <a href={siteData.privacy}>Политика конфиденциальности</a>
                </div>
            </div>
        </div>

    </div>
  )
}
