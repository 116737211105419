import { FC, useState } from "react";
import css from './index.module.css';
import Popup from "@components/popup";
import { toast } from "sonner";
import axios from "axios";

interface PopupInfo {
  showPopup: boolean;
  setShowPopup: any;
  id: string;
}

const ReviewPopup: FC<PopupInfo> = ({ showPopup, setShowPopup, id }) => {
  const [comment, setComment] = useState('');
  const [stars, setStars] = useState(0);

  const handleStarClick = (starIndex: number) => {
    setStars(starIndex);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (stars <= 0) return toast.error('Заполните поле оценки заказа');

    const data = {
        stars: stars,
        comment: comment
    }

    axios.post(`${import.meta.env.VITE_API_URL}/user/order/review/${id}`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        }
    })
    .then(_ => {
      toast.success('Отзыв отправлен, спасибо!');
    })
    .catch(error => {
      console.log(error);
      toast.error('Ошибка отправки отзыва');
    });
  };

  return (
    <Popup showPopup={showPopup} setShowPopup={setShowPopup}>
        <h2>оцените заказ</h2>

        <label className={css.label}>оценка заказа</label>
        <div className={css.stars}>
            {[1, 2, 3, 4, 5].map((starIndex) => (
            <i
                key={starIndex}
                className={`${css.star} fi fi-${stars >= starIndex ? 'sc' : 'rr'}-star`}
                onClick={() => handleStarClick(starIndex)}
            />
            ))}
        </div>

        <label className={css.label}>комментарий</label>
        <textarea placeholder="напишите всё, что считаете нужным" value={comment} onChange={(e) => setComment(e.target.value)} />

        <button className={css.button} onClick={handleSubmit}>отправить отзыв</button>
    </Popup>
  )
}

export default ReviewPopup;