import { FC } from "react";
import { Link } from "react-router-dom";
import css from './index.module.css';
import * as Types from '../../types';
import useUserData from "@hooks/useUserData";
import Popup from "@components/popup";
import { toast } from "sonner";

interface PopupInfo {
  showPopup: boolean, 
  setShowPopup: any,
  selectedCard: Types.ConnCard | null,
  setSelectedCard: any
}

const BankCards: FC<PopupInfo> = ({ showPopup, setShowPopup, selectedCard, setSelectedCard }) => {
  const { userData } = useUserData();

  const selectCard = (card: Types.ConnCard) => {
    setShowPopup(false);
    setSelectedCard(card);
    toast.success('Карта выбрана');
  }

  return (
    <Popup showPopup={showPopup} setShowPopup={setShowPopup}>
        <h2>выберите карту</h2>
        
        <div className={css.addressList}>
          {userData.payments?.map((item: Types.ConnCard) => (
            <div className={`${css.address} ${selectedCard && css.active}`} onClick={() => selectCard(item)}>
                <a>{item.data.title}</a>
            </div>
          ))}
        </div>

        <Link className={css.add} to={'/account'}>добавить новый способ</Link>
    </Popup>
  )
}

export default BankCards;