import { FC } from "react";
import { Link } from "react-router-dom";
import css from './index.module.css';
import Header from "../header";
import Footer from "../footer";

interface MainContent {
  children: any;
  cover: boolean;
  image?: string;
  width?: number;
  coverLink?: string;
}
const MainContent:FC<MainContent> = ({ children, cover, image, width, coverLink }) => {
  return (
    <div className={css.sweetKit}>
      <Header />
      {cover && 
        <div className={css.cover} style={{ backgroundImage: `url(${image})`}}>
          <div className={css.moreBtn}>
            {coverLink && <Link to={coverLink}>подробнее</Link> }
          </div>
        </div>
      }

      <div className={cover ? css.sweetKit_bodyCover : css.sweetKit_body}>
        <div className={cover ? css.sweetKit_body_content : css.sweetKit_body_contentnCover} style={{ maxWidth: width }}>
          {children}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MainContent;