import { Link } from "react-router-dom";
import css from './index.module.css';

import LogoBlack from '@assets/images/logo_bl.svg';
import useUserData from "@hooks/useUserData";

export default function Footer() {
    const { siteData } = useUserData();

    return (
        <footer>
            <div className={css.left}>
                <img className={css.logo} src={LogoBlack} alt="Sweet Cat Shop" />

                <div className={css.social}>
                    <a href="https://vk.com/sweetcatshop"><i className="fi fi-brands-vk"></i></a>
                    <a href="https://t.me/sweetkatshop"><i className="fi fi-brands-telegram"></i></a>
                    <a href="https://t.me/sweetkatshop"><i className="fi fi-brands-tik-tok"></i></a>
                </div>

                <a>2024 © Sweet Cat Shop</a>
            </div>

            <div className={css.right}>
                <div className={css.links}>
                    <h3>компания</h3>   
                    <Link to={``}>о нас</Link>
                    <Link to={``}>сотрудничество</Link>
                    <Link to={``}>магазины сети</Link>
                    <Link to={``}>обратная связь</Link>
                </div>

                <div className={css.links}>
                    <h3>продукция</h3>   
                    <Link to={``}>канцтовары</Link>
                    <Link to={``}>аксессуары</Link>
                    <Link to={``}>одежда</Link>
                    <Link to={``}>мягкие игрушки</Link>
                    <Link to={``}>слаймы</Link>
                    <Link to={``}>еда и напитки</Link>
                    <Link to={``}>подарочные наборы</Link>
                </div>

                <div className={css.links}>
                    <h3>магазин</h3>   
                    <Link to={``}>доставка и оплата</Link>
                    <Link to={``}>программа лояльности</Link>
                    <Link to={``}>политика безопасности</Link>
                </div>

                <div className={css.links}>
                    <h3>полезно</h3>   
                    <Link to={``}>акции</Link>
                    <Link to={``}>подарочные карты</Link>
                    <Link to={``}>размеры</Link>
                </div>

                <div className={css.contact}>
                    <a>связаться с нами</a>
                    <a className={css.phone}>{siteData.phone}</a>
                    <a className={css.email}>{siteData.email}</a>

                    <a className={css.recommend}>На информационном ресурсе применяются рекомендательные технологии (информационные технологии предоставления информации на основе сбора, систематизации и анализа сведений, относящихся к предпочтениям пользователей сети "Интернет", находящихся на территории Российской Федерации).</a>

                    <Link className={css.legalLink} to={siteData.terms}>Оферта</Link>
                    <Link className={css.legalLink} to={siteData.privacy}>Политика конфиденциальности</Link>

                    <div className={css.legal}>
                        <a>ООО “СВИТ КЭТ ШОП” </a>
                        <a>ИНН 3702199939</a>
                        <a>ОГРН 1183702010304</a>
                    </div>
                </div>
            </div>
        </footer>
    )
}