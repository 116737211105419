import { FC } from 'react';
import css from './index.module.css';

interface MiniCategory {
    title: string, 
    description: string, 
    image: string
}

const MiniCategory: FC<MiniCategory> = ({ title, description, image }) => {
    return (
        <div className={css.miniCategory} style={{ backgroundImage: `url(${image})` }}>
            <i className={`fi fi-rr-angle-circle-right ${css.arrow}`}></i>
            <h2>{title}</h2>
            <a>{description}</a>
        </div>
    );
};

export default MiniCategory;