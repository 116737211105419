import { useState, useEffect } from "react";
import axios from 'axios';
import css from './index.module.css';

import SEO from "@components/seo";
import MainContent from "@components/content";
import Order from "@components/order";
import Logo from '@assets/images/logo.svg';

import useUserData from "@hooks/useUserData";
import * as Popups from './popups';
import * as Types from '../../types';

export default function Account() {
  const { userData, logout } = useUserData();
  const [bonusHistory, setBonusHistory] = useState<Types.BonusHistory[]>([]);

  const [page, setPage] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupDelete, setShowPopupDelete] = useState(false);
  const [showPopupCard, setShowPopupCard] = useState(false);
  const [showPopupEdit, setShowPopupEdit] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState('');
  const [orders, setOrders] = useState<Types.Order[]>([]);

  useEffect(() => {
    axios.get(`${import.meta.env.VITE_API_URL}/user/loyality/history`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      }
    })
    .then(response => {
      setBonusHistory(response.data);
    })
    .catch(error => {
      console.log(error);
    });

    axios.get(`${import.meta.env.VITE_API_URL}/user/orders`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      }
    })
    .then(response => {
      setOrders(response.data);
    })
    .catch(error => {
      console.log(error);
    });
  }, []);
  
  const formatNumber = (num: number) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  };

  const deleteAddress = (id: string) => {
    setShowPopupDelete(true);
    setSelectedAddress(id);
  }

  function convertToDate(isoDate: any) {
    const date = new Date(isoDate);
    return new Intl.DateTimeFormat('ru-Ru', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }).format(date);
  }

  return (
    <MainContent cover={false} width={1400}>    
      <Popups.Address showPopup={showPopup} setShowPopup={setShowPopup}  />
      <Popups.AddressDelete addressID={selectedAddress} showPopup={showPopupDelete} setShowPopup={setShowPopupDelete} />
      <Popups.Cards showPopup={showPopupCard} setShowPopup={setShowPopupCard} />
      <Popups.Edit showPopup={showPopupEdit} setShowPopup={setShowPopupEdit} />

      <SEO 
        title="Управление аккаунтом"
        description="Управление аккаунтом"
        ogUrl="https://sweetcatshop.ru/"
      />

      <div className={css.account}>
        {page === 0 ? (
          <div className={css.col1}>
            <h2 className={css.orderName}>заказы</h2>
            <div className={css.orders}>
              {orders.map(item => (
                <Order 
                  id={item.id}
                  createdAt={item.createdAt}
                  status={item.status}
                  totalPrice={item.totalPrice}
                  products={item.products}
                />
              ))}

              {orders.length <= 0 && <a style={{ fontSize: 18 }}>заказов нет...</a>}
            </div>
          </div>
        ) : (
          <div className={css.col1}>
            <a className={css.back} onClick={() => setPage(0)}><i className="fi fi-rr-angle-small-left"></i> назад к заказам</a>

            <div className={css.bonusHistory}>
              {bonusHistory.map(item => (
                <div className={css.bonusItem}>
                  <div className={css.textBlock}>
                    <a>{convertToDate(item.date)}</a>
                    <h3>{item.branch.name || item.invoice_num}</h3>
                  </div>
                  <div className={`${css.payBlock} ${parseInt(item.value) > 0 && css.plus}`}>
                    <a>{item.value}</a>
                    <img src={Logo} alt="Logo" style={{ width: 20, position: 'relative', top: 1, marginLeft: 5 }} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        <div className={css.col2}>
          <div className={css.accInfo}>
            <div className={css.info}>
              <img className={css.avatar} src={userData?.avatar} alt="avatar" />
              <div className={css.textInfo}>
                <a className={css.name}>{userData.firstName ? userData.firstName : "Не указано имя"} {userData.lastName}</a>
                <a className={css.phone}>{userData.phone}</a>
              </div>
            </div>

            <div style={{ display: 'flex', gap: 10 }}>
              <i className="fi fi-rr-pencil" onClick={() => setShowPopupEdit(true)}></i>
              <i className="fi fi-rr-exit" onClick={() => logout()}></i>
            </div>
          </div>
          
          <div className={css.accBtn}>
            {userData.loyality &&
              <div className={css.btn} onClick={() => setPage(1)}>
                <a>{userData.loyality.balance} <img src={Logo} alt="Logo" style={{ width: 20, position: 'relative', top: 1, marginLeft: 5 }} /></a>
                <p>на бонусном счете №{userData.loyality.card_num}</p>
              </div>
            }

            <div className={css.btn} onClick={() => setShowPopupCard(true)}>
              <a>способы оплаты</a>
              <p>управление</p>
            </div>
          </div>

          <div className={css.addressList}>
            <h2>адреса <i className="fi fi-rr-add" onClick={() => setShowPopup(true)}/></h2>

            {userData.addresses?.map((item: Types.Address) => (
              <div className={css.address} onClick={() => deleteAddress(item.uuid)}>
                {item.type === 0 ? <i className="fi fi-rr-shop"></i> : <i className="fi fi-rr-marker"></i>}

                <div className={css.textInfo}>
                  <b>{item.address}</b>
                  <a>{item.type === 0 ? "заберете в магазине" : `доставим курьером за ${formatNumber(item.deliveryPrice)} ₽` }</a>
                </div>
              </div>
            ))}

            {userData.addresses?.length <= 0 && <a style={{ fontSize: 18 }}>добавьте адрес для доставки или самовывоза</a>}
          </div>
        </div>
      </div>
    </MainContent>
  )
}