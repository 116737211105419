import { Link } from "react-router-dom";
import css from './index.module.css';
import SEO from "@components/seo";
import Cate from '@assets/images/cate.png';
import useUserData from "@hooks/useUserData";

export default function EmptyCart () {
    const { siteData } = useUserData();

  return (
    <div className={css.container}>
        <SEO 
            title="Корзина пуста"
            description="Sweet Cat Shop"
            ogUrl="https://sweetcatshop.ru/"
        />

        <div className={css.component}>        
            <div>    
                <h1>корзина пустая</h1>
                <a>добавьте что-нибудь в корзину и возвращайтесь сюда</a>

                <div className={css.back}><Link to="/">вернуться на главную</Link></div>
            </div>

            <div>
                <img src={Cate} alt="Logo" />
                <div className={css.legal}>
                    <a href={siteData.terms}>Лицензионное соглашение</a>
                    <a href={siteData.privacy}>Политика конфиденциальности</a>
                </div>
            </div>
        </div>

    </div>
  )
}
