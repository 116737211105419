import { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import css from './index.module.css';
import Content from '@components/adminNavigation';
import * as Types from '../../../types';
import axios from "axios";
import { statusText } from '../../account/order.info';
import SEO from '@components/seo';

export default function Orders() {
  const navigate = useNavigate();
  const [orders, setOrders] = useState<Types.Order[]>();

  function convertToDate(isoDate: any) {
    const date = new Date(isoDate);
    return new Intl.DateTimeFormat('ru-Ru', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }).format(date);
  }

  useEffect(() => {
    axios.get(`${import.meta.env.VITE_API_ADM_URL}/orders`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      }
    }).then(response => {
      setOrders(response.data);
    }).catch(error => {
      console.log(error);
    });
  }, []);

  return (
    <Content>
      <SEO 
        title={'Заказы'}
        description={'Админ-панель Sweet Cat Shop'}
        ogUrl={`https://sweetcatshop.ru/`}
      />

      <h1 className={css.head}>Список заказов</h1>

      <div className={css.table}>
        <div className={css.tableHeader}>
            <a>№</a>
            <a>Дата</a>
            <a>Итого</a>
            <a>Статус</a>
            <a>Адрес доставки</a>
        </div>

        <div className={css.tableBody}>
          {orders?.map(item => (
            <div className={css.tableDiv} onClick={() => navigate(`/manage/order/${item.id}`)}>
              <a>{item.id}</a>
              <a>{convertToDate(item.createdAt)}</a>
              <a>{item.totalPrice} ₽</a>
              <a>{statusText[item.status]}</a>
              <a>{item.delivery?.address}</a>
            </div>
          ))}
        </div>
    </div>
    </Content>
  );
}
