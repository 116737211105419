import { FC } from "react";
import css from './index.module.css';

interface Tab {
  id: number | string,
  color: string
}

interface ColorTabs {
  sections: Tab[],
  active?: number,
  setActive?: any
}

const ColorTabs:FC<ColorTabs> = ({ sections, active, setActive }) => {
  return (
    <div className={css.sections}>
        {sections.map(section => (
            <div className={`${css.section} ${active === section.id ? css.active : ''}`} style={{ background: `${section.color}` }} onClick={() => setActive(section.id)}></div>
        ))}
    </div>
  );
};

export default ColorTabs;