import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import css from './index.module.css';
import Content from '@components/adminNavigation';
import axios from "axios";
import * as Types from '../../../types';
import SEO from '@components/seo';

export default function Users() {
  const navigate = useNavigate();
  const [users, setUsers] = useState<Types.User[]>([]);
  const [query, setQuery] = useState('');

  useEffect(() => {
    axios.get(`${import.meta.env.VITE_API_ADM_URL}/users`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      }
    }).then(response => {
        setUsers(response.data);
    }).catch(error => {
      console.log(error);
    });
  }, []);

  const handleSubmit = (event: any) => {
    event.preventDefault();

    axios.get(`${import.meta.env.VITE_API_ADM_URL}/users?query=${query}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        }
      }).then(response => {
        setUsers(response.data);
      }).catch(error => {
        console.log(error);
      });
  };

  return (
    <Content>
      <SEO 
        title={'Управление пользователями'}
        description={'Админ-панель Sweet Cat Shop'}
        ogUrl={`https://sweetcatshop.ru/`}
      />

      <h1 className={css.head}>Пользователи (последние 50)</h1>
      
      <form className={css.search} onSubmit={handleSubmit}>
          <input placeholder='Поиск (по номеру, email или public name)' value={query} onChange={(e) => setQuery(e.target.value)} />
          <button type='submit'><i className="fi fi-rr-search" /></button>
      </form>

      <div className={css.table}>
          <div className={css.tableHeader}>
              <a>Номер телефона</a>
              <a>Имя Фамилия</a>
              <a>Публичное имя</a>
              <a>Уровень доступа</a>
          </div>

          <div className={css.tableBody}>
              {users.map((item) => (
                  <div className={css.tableDiv} onClick={() => navigate(`/manage/user/${item.uuid}`)}>
                      <a>{item.phone}</a>
                      <a>{item.firstName} {item.lastName}</a>
                      <a>{item.publicName}</a>
                      <a>{item.access}</a>
                  </div>
              ))}
          </div>
      </div>
    </Content>
  );
}