import { useEffect, useRef, useState } from 'react';
import css from './index.module.css';
import Content from '@components/adminNavigation';
import axios from 'axios';
import * as Types from '../../../types';
import useUserData from "@hooks/useUserData";
import SEO from '@components/seo';

interface Chat {
  user: Types.User;
  msgs: Types.Message[];
}

export default function Chats() {
  const [selectedChat, setSelectedChat] = useState<Chat | null>();
  const [users, setUsers] = useState<Types.User[]>([]);
  const [isConnected, setIsConnected] = useState(false);
  const wsRef = useRef<WebSocket | null>(null);
  const reconnectTimer = useRef<NodeJS.Timeout | null>(null);
  const [msg, setMsg] = useState('');
  const { userData } = useUserData();

  useEffect(() => {
    loadChats();
  }, []);

  const loadChats = () => {
    axios.get(`${import.meta.env.VITE_API_ADM_URL}/chats`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      }
    }).then(response => {
      setUsers(response.data);
    }).catch(error => {
      console.log(error);
    });
  }

  const selectChat = (user: Types.User) => {
    if (wsRef.current && isConnected) {
      wsRef.current.send(JSON.stringify({ type: 2, chat: user.uuid }));
    }

    axios.get(`${import.meta.env.VITE_API_ADM_URL}/chat/${user.uuid}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      }
    }).then(response => {
      setSelectedChat(response.data);
    }).catch(error => {
      console.log(error)
    });
  }
  
  const connect = () => {
    const ws = new WebSocket(import.meta.env.VITE_API_WEBSOCKET);

    ws.onopen = () => {
      const token = localStorage.getItem('authToken');
      ws.send(JSON.stringify({ type: 0, jwt: token, chat: 'adm' }));
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);

      if (data.type == 0 && data.status == 'Authorized') {
        setIsConnected(true);
        if (reconnectTimer.current) {
          clearTimeout(reconnectTimer.current);
        }
      } else if (data.type === 1) {
        setSelectedChat((prevChat: any) => {
          if (!prevChat) {
            return {
              user: data?.user,
              msgs: [{ msgType: data?.msgType, from: data?.from, to: data?.to, msg: data?.msg, createdAt: data?.createdAt }],
            };
          }
        
          return {
            ...prevChat,
            msgs: [...prevChat.msgs, { from: data?.from, to: data?.to, msg: data?.msg, createdAt: data?.createdAt }],
          };
        });
      }
    };

    ws.onclose = () => {
      setIsConnected(false);
      reconnect();
    };

    ws.onerror = (_) => {
      ws.close();
    };

    wsRef.current = ws;
  };

  const reconnect = () => {
    if (reconnectTimer.current) {
      clearTimeout(reconnectTimer.current);
    }
    reconnectTimer.current = setTimeout(() => {
      connect();
    }, 3000);
  };

  useEffect(() => {
    connect();

    return () => {
      if (wsRef.current) {
        wsRef.current.close();
      }
      if (reconnectTimer.current) {
        clearTimeout(reconnectTimer.current);
      }
    };
  }, [import.meta.env.VITE_API_WEBSOCKET]);

  const sendMessage = () => {
    if (wsRef.current && isConnected) {
      wsRef.current.send(JSON.stringify({ type: 1, msg }));
    } else {
      console.warn('WebSocket is not connected. Message not sent.');
    }
  };

  const handleFileUpload = async (file: File) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(`${import.meta.env.VITE_API_ADM_URL}/uploadFile`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
      });

      const imageUrl = response.data.url;
      wsRef.current!.send(JSON.stringify({ type: 1, msgType: 1, msg: imageUrl }));
    } catch (error) {
      console.log(error);
    }
  };

  const setStatus = (id: string) => {
    if (!selectedChat) return;

    axios.post(`${import.meta.env.VITE_API_ADM_URL}/chat/${selectedChat.user.uuid}`, { status: id }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      }
    }).then(_ => {
      if (id === '0') {
        loadChats();
        setSelectedChat(null);
      } else {
        selectChat(selectedChat.user);
      }
    }).catch(error => {
      console.log(error);
    });
  }

  return (
    <Content>
      <SEO 
        title={'Чаты'}
        description={'Админ-панель Sweet Cat Shop'}
        ogUrl={`https://sweetcatshop.ru/`}
      />
      
      <div className={css.content}>
        <div className={css.chats}>
          {users.map(user => (
            <div className={css.chat} onClick={() => selectChat(user)}>
              <img src="https://amu.edu.kz/upload/default-avatar.jpg" alt='avatar' />
              <div className={css.info}>
                <b className={css.name}>{user.firstName ? user.firstName : user.phone}</b>
                <a className={css.name}>{user.phone}</a>
              </div>
            </div>
          ))}
          {users.length <= 0 && <div className={css.notSelected}>Нет доступных чатов</div>}
        </div>
        
        {selectedChat != null ?
          <div className={css.messages}>
            <div className={css.chat}>
              <div className={css.information}>
                <img src="https://amu.edu.kz/upload/default-avatar.jpg" alt='avatar' />
                <div className={css.uInfo}>
                  <b className={css.name}>{selectedChat?.user?.firstName ? selectedChat?.user?.firstName : selectedChat?.user?.phone}</b>
                  <b className={css.status}>{selectedChat?.user?.msgStatus == '1' ? "Ожидает ответа" : "В работе у администратора"}</b>
                </div>
              </div>
              
              <div className={css.actions}>
                <a href={`tel:${selectedChat?.user?.phone}`}><i className="fi fi-rr-phone-call"></i></a>
              </div>
            </div>

            <div style={{ height: '100%', overflow: 'scroll', marginTop: 10 }}>
              <div className={css.msgList}>
                {selectedChat?.msgs.map(item => (
                  item?.msgType === 0 ?
                    <div className={`${item.to == 'admin' ? css.from : css.to}`}>
                      <a className={css.text}>
                        {item.msg}
                      </a>
                    </div>
                  :
                  <div className={`${css.file} ${item.to == 'admin' ? css.from : css.to}`}>
                    <a href={item.msg} className={`${css.text}`} target="_blank" rel="noopener noreferrer"><i className="fi fi-rr-picture"></i> файл</a>
                  </div>
                ))}
              </div>
            </div>

            {selectedChat.user.msgStatus === userData.id &&
            <>
              <div className={css.input}>
                <textarea placeholder='Введите сообщение' onChange={(event) => setMsg(event.target.value)} />

                <div className={css.file}>
                  <label htmlFor="fileInput" style={{ display: "block", textAlign: "center", backgroundColor: '#2b2a2a', color: 'white', padding: '10px', cursor: 'pointer', width: "100%", borderRadius: 10, fontSize: 15, boxSizing: 'border-box', border: '1px solid var(--border-color)' }}><i className="fi fi-rr-picture" style={{ top: 2}}></i></label>
                  <input type="file" id="fileInput" name="avatar" style={{ display: "none" }} onChange={(e) => { if (e.target.files && e.target.files[0]) { handleFileUpload(e.target.files[0]); }} } accept="image/*"/>
                </div>

                <div className={css.send} onClick={sendMessage}>
                  <i className="fi fi-rr-paper-plane"></i>
                </div>
              </div>

              <div className={css.btnAction} onClick={() => setStatus('0')}>Закрыть</div>
            </>
            }
          
            {selectedChat.user.msgStatus === '1' && <div className={css.btnAction} onClick={() => setStatus(userData.id)}>Взять в работу</div> }
          </div>
        : 
        <div className={css.notSelected}>
          <i className="fi fi-rr-info"></i>
          <a>Не выбран пользователь</a>
        </div>
        }
      </div>
    </Content>
  );
}
