import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from 'axios';
import css from './index.module.css';

import SEO from "@components/seo";
import MainContent from "@components/content";
import MiniProduct from "@components/miniProduct";
import Email from "@components/email";

import * as Types from '../../types';

export default function Search() {
  const { query } = useParams();
  const [products, setProducts] = useState<Types.Product[]>();
  
  useEffect(() => {
    axios.get(`${import.meta.env.VITE_API_URL}/search?query=${query}`, {
      headers: {
        'Content-Type': 'application/json',
      }
    }).then(response => {
      setProducts(response.data);
    }).catch(error => {
      console.log(`Error fetch products: ${error}`)
    });
  }, [query]);

  return (
    <MainContent cover={false}>    
      <SEO 
        title={`${query}`}
        description={`Поисковой запрос: ${query}`}
        ogUrl={`https://sweetcatshop.ru/search/${query}`}
      /> 

      <h2>Поисковой запрос: {query}</h2>

      <div className={css.productList}>
        {products?.map((product: Types.Product) => (
          <MiniProduct 
            id={product.id}
            title={product.name}
            price={product.price}
            image={product.images[0]}
          />
        ))}
      </div>

      <Email />
    </MainContent>
  )
}