import { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import css from './index.module.css';
import Content from '@components/adminNavigation';
import axios from "axios";
import * as Types from '../../../types';
import { toast } from 'sonner';
import { statusText } from '@pages/account/order.info';
import CartProduct from '@components/cartProduct';
import SEO from '@components/seo';

export default function OrderAdmin() {
  const { id } = useParams();
  const [order, setOrder] = useState<Types.Order>();
  
  useEffect(() => {
    axios.get(`${import.meta.env.VITE_API_ADM_URL}/order/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      }
    }).then(response => {
        setOrder(response.data);
    }).catch(error => {
      console.log(error);
    });
  }, []);

  function convertToDate(isoDate: any) {
    const date = new Date(isoDate);
    return new Intl.DateTimeFormat('ru-Ru', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }).format(date);
  }

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    axios.post(`${import.meta.env.VITE_API_ADM_URL}/order/${id}`, formData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      }
    })
    .then(_ => {
      toast.success('Обновлено успешно')
    })
    .catch(error => {
      console.log(error);
      toast.error('Ошибка обновления');
    });
  };

  return (
    <Content>
      <SEO 
        title={'Управление заказом'}
        description={'Админ-панель Sweet Cat Shop'}
        ogUrl={`https://sweetcatshop.ru/`}
      />

      <h1 className={css.head}>Заказ №{order?.id} </h1>
      <div className={css.flex}>
        <div className={css.col1}>
            <div className={css.products}>
              {order?.products.map((item: Types.CartData) => (
                <CartProduct 
                  id={item.product.id}
                  image={item.product.images[0]}
                  title={item.product.name}
                  desc={`${item.product.sizes[item.params.size]}`}
                  price={item.product.price}
                  width={'100%'}
                />
              ))}
            </div>
            
            <div className={css.total}>
              <div className={css.totalPrice}>
                <a>итого</a>
                <h1>{order?.totalPrice} ₽</h1>
              </div>
            </div>
        </div>

        <div className={css.col2}>
            <div className={css.client}>
              <img src={order?.user?.avatar} className={css.avatar} alt='avatar' />
              <div className={css.textInfo}>
                  <h2>{order?.user?.firstName} {order?.user?.lastName}</h2>
                  <a>{order?.user?.phone}</a>
              </div>
            </div>

            <form onSubmit={handleSubmit}>
              <div className={css.inputGrid}>
                <label>Статус</label>
                <select name='status' defaultValue={order?.status}>
                    <option value={0}>{statusText[0]}</option>
                    <option value={1}>{statusText[1]}</option>
                    <option value={2}>{statusText[2]}</option>
                    <option value={3}>{statusText[3]}</option>
                    <option value={4}>{statusText[4]}</option>
                    <option value={5}>{statusText[5]}</option>
                </select>
              </div>

              <div className={css.inputGrid}>
                <label>Комментарий</label>
                <textarea name='adminComment' defaultValue={order?.adminComment} placeholder='Комментарий (админа или менеджера)' />
              </div>

              <button className={css.saveBtn} type='submit'>Обновить</button>
            </form>

            <div className={css.inputGrid}>
              <label>Адрес доставки</label>
              <b>{order?.delivery.address} ({ order?.delivery.type === 0 ? 'самовывоз' : 'доставка' })</b>
            </div>

            <div className={css.inputGrid}>
              <label>Стоимость доставки</label>
              <b>{order?.delivery.deliveryPrice} ₽</b>
            </div>

            <div className={css.inputGrid}>
              <label>Оплачено бонусами</label>
              <b>{order?.bonus} ₽</b>
            </div>

            <div className={css.inputGrid}>
              <label>ID банковского платежа</label>
              <b>{order?.kassaID}</b>
            </div>

            <div className={css.inputGrid}>
              <label>Создан</label>
              <b>{convertToDate(order?.createdAt || null)}</b>
            </div>
            
            <div className={css.inputGrid}>
              <label>Оценка</label>
              <b><i className="fi fi-sc-star"></i> {order?.review.stars}/5</b>
            </div>

            <div className={css.inputGrid}>
              <label>Комментарий к отзыву</label>
              <b>{order?.review.comment}</b>
            </div>
        </div>
      </div>
    </Content>
  );
}
