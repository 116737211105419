import { useState, useEffect, FC } from "react";
import css from './index.module.css';
import * as Types from '../../types';
import axios from "axios";
import { toast } from "sonner";
import Tabs from "@components/tabs";
import useUserData from "@hooks/useUserData";

interface Addresssuggestion {
  id: string;
  address: string;
}

interface Popup {
  addressID?: string,
  showPopup: boolean, 
  setShowPopup: any
}

export const Address: FC<Popup> = ({ showPopup, setShowPopup }) => {
  const [type, setType] = useState<number>(1);
  const [query, setQuery] = useState<string>('');
  const [suggestions, setSuggestions] = useState<Addresssuggestion[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { refreshUserData } = useUserData();

  useEffect(() => {
    const fetchSuggestions = async () => {
      if (query.length < 3) {
        setSuggestions([]);
        return;
      }

      setLoading(true);
      try {
        if (type === 0) return;

        const response = await fetch(
          `https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address?query=${query}`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${import.meta.env.VITE_API_DADATA}`
            },
            body: JSON.stringify({ query })
          }
        );

        const data = await response.json();
        const addressSuggestions: Addresssuggestion[] = data.suggestions.map((suggestion: any) => ({
          id: suggestion.value,
          address: suggestion.value
        }));

        setSuggestions(addressSuggestions);
      } catch (error) {
        console.error('Ошибка получения подсказок', error);
        setSuggestions([]);
      } finally {
        setLoading(false);
      }
    };

    const timer = setTimeout(fetchSuggestions, 300);
    return () => clearTimeout(timer);
  }, [query]);

  useEffect(() => {
    if (type === 0) {
      const addressSuggestions: Addresssuggestion[] = [
        {
          id: 'Большая Московская ул., 19А, Владимир',
          address: 'Большая Московская ул., 19А, Владимир'
        }
      ];
      
      setLoading(false);
      setSuggestions(addressSuggestions);
    } else {
      setQuery('');
      setSuggestions([]);
    }
  }, [type]);

  const handleSelectAddress = (address: string) => {
    setQuery(address);
    setSuggestions([]);
    if (type === 0) saveAddress(address);
  };

  const saveAddress = (address = query) => {
    const formData = new FormData();
    formData.append('address', address);
    formData.append('type', type.toString());

    axios.post(`${import.meta.env.VITE_API_URL}/user/addresses`, formData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      }
    }).then(_ => {
      toast.success('Адрес успешно сохранен');
      setShowPopup(false);
      refreshUserData();
    }).catch(error => {
      toast.error('Не удалось сохранить адрес');
      console.log(error);
    });
  }
  
  return (
      <div className={showPopup ? css.popup : `${css.popup} ${css.hidden}`}>
          <div className={css.popupBG} onClick={() => setShowPopup(false)} />
          <div className={css.popupBody}>
              <div className={css.popupContent}>
                <h2>добавить адрес</h2>
              
                <Tabs 
                  sections={
                    [
                      {
                        id: 1,
                        name: 'курьером'
                      },
                      {
                        id: 0,
                        name: 'в магазине'
                      }
                    ]
                  }
                  active={type}
                  setActive={setType}
                  isFull={true}
                />

                {type === 1 &&
                  <input
                    type="text"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    placeholder="Введите адрес"
                    className={css.queryAddress}
                  />
                }
                
                {loading && (
                  <a className={css.info}>загрузка...</a>
                )}

                {suggestions.length > 0 && !loading && (
                  <>
                    <a className={css.info}>выберите адрес из списка</a>

                    <div className={css.suggestions}>
                      {suggestions.map((suggestion) => (
                        <div
                          key={suggestion.id}
                          onClick={() => handleSelectAddress(suggestion.address)}
                          className={css.suggestion}
                        >
                          {suggestion.address}
                        </div>
                      ))}
                    </div>
                  </>
                )}
                
                <div className={css.checkout} onClick={() => saveAddress()}>сохранить адрес</div>
              </div>
          </div>
      </div>
  )
}

export const AddressDelete: FC<Popup> = ({ addressID, showPopup, setShowPopup }) => {
  const { refreshUserData } = useUserData();

  const saveAddress = () => {
    axios.delete(`${import.meta.env.VITE_API_URL}/user/addresses/${addressID}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      }
    }).then(_ => {
      toast.success('Адрес успешно удален');
      setShowPopup(false);
      refreshUserData();
    }).catch(error => {
      toast.error('Не удалось удалить адрес');
      console.log(error);
    });
  }
  
  return (
      <div className={showPopup ? css.popup : `${css.popup} ${css.hidden}`}>
          <div className={css.popupBG} onClick={() => setShowPopup(false)} />
          <div className={css.popupBody}>
              <div className={css.popupContent}>
                <h2>удалить адрес?</h2>
                
                <div className={css.checkout} onClick={() => saveAddress()}>удалить адрес</div>
              </div>
          </div>
      </div>
  )
}

export const Cards: FC<Popup> = ({ showPopup, setShowPopup }) => {
  const { userData } = useUserData();

  const addNewCard = () => {
    axios.post(`${import.meta.env.VITE_API_URL}/pay/connect/`, {}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      }
    }).then(response => {
      const data = response.data;
      if (data.url) {
        window.location.href = data.url;
      } else {
        toast.error('Не удалось добавить карту');
      }
    }).catch(error => {
      console.log(error);
      toast.error('Не удалось добавить карту');
    });
  }
  
  return (
      <div className={showPopup ? css.popup : `${css.popup} ${css.hidden}`}>
          <div className={css.popupBG} onClick={() => setShowPopup(false)} />
          <div className={css.popupBody}>
              <div className={css.popupContent}>
                <h2>привязанные карты</h2>

                <div className={css.savedCards}>
                  {userData.payments?.map((item: Types.ConnCard) => (
                    <div className={css.savedCard}>
                      <a>{item.data.title}</a>
                    </div>
                  ))}
                </div>

                <div className={css.checkout} onClick={() => addNewCard()}>добавить карту</div>
              </div>
          </div>
      </div>
  )
}

export const Edit: FC<Popup> = ({ showPopup, setShowPopup }) => {
  const { userData, refreshUserData } = useUserData();
  const [newAvatar, setNewAvatar] = useState('');
 
  useEffect(() => {
    setNewAvatar(userData?.avatar);
  }, [userData]);

  const handleFileUpload = async (file: File) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(`${import.meta.env.VITE_API_ADM_URL}/uploadFile`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
      });

      const imageUrl = response.data.url;
      setNewAvatar(imageUrl);

      toast.success('Аватарка загружена успешно');
    } catch (error) {
      console.error("Ошибка загрузки файла:", error);
    }
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    formData.append('avatar', newAvatar);

    axios.post(`${import.meta.env.VITE_API_URL}/user`, formData, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        }
    })
    .then(_ => {
      toast.success('Информация обновлена');
      refreshUserData();
    })
    .catch(error => {
      console.log(error);
      toast.error('Ошибка авторизации');
    });
  };
  
  return (
    <div className={showPopup ? css.popup : `${css.popup} ${css.hidden}`}>
        <div className={css.popupBG} onClick={() => setShowPopup(false)} />
        <div className={css.popupBody}>
            <div className={css.popupContent}>
              <h2>изменить информацию</h2>

              <form onSubmit={handleSubmit}>
                <div className={css.gridForm}>
                  <label>аватарка</label>
                  
                  <div className={css.uplAvatar}>
                    {newAvatar && <img src={newAvatar} className={css.avatar} alt="avatar" /> }
                    <label htmlFor="fileInput" style={{ display: "block", textAlign: "center", backgroundColor: '#2b2a2a', color: 'white', padding: '10px', cursor: 'pointer', width: "100%", borderRadius: 10, fontSize: 15, boxSizing: 'border-box', border: '1px solid var(--border-color)' }}>загрузить новую аватарку</label>
                    <input type="file" id="fileInput" style={{ display: "none" }} onChange={(e) => { if (e.target.files && e.target.files[0]) { handleFileUpload(e.target.files[0]); }} } accept="image/*"/>
                  </div>
                </div>

                <div className={css.gridForm}>
                  <label>имя</label>
                  <input name="firstName" type="text" placeholder="имя" defaultValue={userData.firstName} />
                </div>

                <div className={css.gridForm}>
                  <label>фамилия</label>
                  <input name="lastName" type="text" placeholder="фамилия" defaultValue={userData.lastName} />
                </div>

                <div className={css.gridForm}>
                  <label>публичное имя</label>
                  <input name="publicName" type="text" placeholder="публичное имя" defaultValue={userData.publicName} />
                </div>

                <div className={css.gridForm}>
                  <label>электронная почта</label>
                  <input name="email" type="text" placeholder="электронная почта" defaultValue={userData.email} />
                </div>

                <button className={css.checkout} type="submit">обновить информацию</button>
              </form>
            </div>
        </div>
    </div>
  )
}