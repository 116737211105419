import { FC } from 'react';
import { Helmet } from 'react-helmet';
import Logo from '@assets/images/logo.svg';

interface SEO {
    title: string, 
    description: string, 
    ogImage?: string, 
    ogUrl: string, 
    favicon?: string
}

const SEO: FC<SEO> = ({ title, description, ogImage=Logo, ogUrl, favicon=Logo }) => {
    return (
        <Helmet>
        <html lang={`ru`} />
        <title>{title} — Sweet Cat Shop</title>
        <meta name="description" content={description} />
        <meta name="keywords" content="" />
        
        <link rel="icon" href={favicon} />
        <link rel="apple-touch-icon" sizes="180x180" href={favicon} />
        <link rel="icon" type="image/png" sizes="32x32" href={favicon} />
        <link rel="icon" type="image/png" sizes="16x16" href={favicon} />

        <meta property="og:type" content="website" />
        <meta property="og:url" content={ogUrl} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={ogImage} />
        <meta property="og:locale" content={'ru_RU'} />
        <meta property="og:site_name" content="Sweet Cat Shop" />
        
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />

        <script type="application/ld+json">
        {`
        {
            "@context": "http://schema.org",
            "@type": "Organization",
            "name": "Sweet Cat Shop",
            "url": "${ogUrl}",
            "logo": "${ogImage}",
            "description": "${description}"
        }
        `}
        </script>
        </Helmet>
    );
};

export default SEO;