import { useEffect, useState, FC } from 'react'
import css from './index.module.css';
import Content from '@components/adminNavigation';
import axios from "axios";
import * as Types from '../../../types';
import Popup from '@components/popup';
import { toast } from 'sonner';
import SEO from '@components/seo';

export default function Promocodes() {
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupDelete, setShowPopupDelete] = useState(false);
  const [promoID, setPromoID] = useState(0);

  const [promocodes, setPromocodes] = useState<Types.Promocode[]>([]);

  const deletePromo = (id: number) => {
    setShowPopupDelete(true);
    setPromoID(id);
  }

  useEffect(() => {
    axios.get(`${import.meta.env.VITE_API_ADM_URL}/promocodes`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      }
    }).then(response => {
      setPromocodes(response.data);
    }).catch(error => {
      console.log(error);
    });
  }, [showPopup, showPopupDelete]);

  function convertToDate(isoDate: any) {
    const date = new Date(isoDate);
    return new Intl.DateTimeFormat('ru-Ru', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }).format(date);
  }

  return (
    <Content>
      <SEO 
        title={'Управление промокодом'}
        description={'Админ-панель Sweet Cat Shop'}
        ogUrl={`https://sweetcatshop.ru/`}
      />

      <CreatePopup showPopup={showPopup} setShowPopup={setShowPopup} />
      <DeletePopup showPopup={showPopupDelete} setShowPopup={setShowPopupDelete} id={promoID} />
      <h1 className={css.head}>Список промокодов <i className="fi fi-rr-add" style={{ float: 'right' }} onClick={() => setShowPopup(true)} /></h1>

      <div className={css.table}>
        <div className={css.tableHeader}>
            <a>Название</a>
            <a>Бонус</a>
            <a>Дата</a>
            <a>Использований</a>
        </div>

        <div className={css.tableBody}>
          {promocodes.map(item => (
            <div className={css.tableDiv} onClick={() => deletePromo(item.id)}>
              <a>{item.promocode}</a>
              <a>{item.price} ₽</a>
              <a>{convertToDate(item.createdAt)}</a>
              <a>{item.uses}</a>
            </div>
          ))}
        </div>
    </div>
    </Content>
  );
}

interface PopupInfo {
  showPopup: boolean;
  setShowPopup: any;
  id?: number;
}

const CreatePopup: FC<PopupInfo> = ({ showPopup, setShowPopup }) => {
  const handleSubmit = (event: any) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    axios.post(`${import.meta.env.VITE_API_ADM_URL}/promocodes`, formData, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        }
    })
    .then(_ => {
      toast.success('Промокод создан');
      setShowPopup(false);
      event.target.reset();
    })
    .catch(error => {
      console.log(error);
    });
  };
  
  return (
  <Popup showPopup={showPopup} setShowPopup={setShowPopup}>
      <h2>Добавить продукт</h2>

      <form onSubmit={handleSubmit}>
              
        <div className={css.inputGrid}>
          <label>Промокод (слитно)</label>
          <input name='promocode' type='text' placeholder='Промокод' />
        </div>

        <div className={css.inputGrid}>
          <label>Бонус (в рублях)</label>
          <input name='price' type='number' placeholder='Бонус' />
        </div>

        <button className={css.saveBtn} type='submit'>Добавить новый промокод</button>
      </form>
  </Popup>
  )
}

const DeletePopup: FC<PopupInfo> = ({ showPopup, setShowPopup, id }) => {
  const handleSubmit = (event: any) => {
    event.preventDefault();

    axios.delete(`${import.meta.env.VITE_API_ADM_URL}/promocode/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      }
    })
    .then(_ => {
      toast.success('Промокод удален');
      setShowPopup(false);
    })
    .catch(error => {
      console.log(error);
    });
  };
  
  return (
  <Popup showPopup={showPopup} setShowPopup={setShowPopup}>
      <h2>Удалить промокод?</h2>

      <form onSubmit={handleSubmit}>
        <button className={css.saveBtn} type='submit' style={{ margin: 0 }}>Удалить промокод</button>
      </form>
  </Popup>
  )
}