import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import css from './index.module.css';

import SEO from "@components/seo";
import MainContent from "@components/content";
import MiniProduct from "@components/miniProduct";
import MiniCategory from "@components/miniCategory";
import Email from "@components/email";
import * as Types from '../../types';
import useUserData from "@hooks/useUserData";

export default function RootPage() {
  const navigate = useNavigate();
  const { siteData } = useUserData();
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState<Types.Product[]>([]);

  const [limit, _] = useState(50);
  const [start, setStart] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    axios.get(`${import.meta.env.VITE_API_URL}/categories`, {
      headers: {
        'Content-Type': 'application/json',
      }
    }).then(response => {
      setCategories(response.data);
    }).catch(error => {
      console.log(error)
    });

    axios.get(`${import.meta.env.VITE_API_URL}/products?limit=${limit}&start=${start}`, {
      headers: {
        'Content-Type': 'application/json',
      }
    }).then(response => {
      if (start === 0) {
        setProducts(response.data);
      } else {
        setProducts((prevProducts: any) => [...prevProducts, ...response.data]);
      }
      setLoading(false);
    }).catch(error => {
      console.log(error);
      setLoading(false);
    });
  }, [limit, start]);

  const handleScroll = useCallback((e: any) => {
    const bottom = e.target.scrollHeight === e.target.scrollTop + e.target.clientHeight;
    if (bottom && !loading) {
      setLoading(true);
      setStart((prevStart) => prevStart + limit);
    }
  }, [loading, limit]);

  useEffect(() => {
    const container = document.getElementById('productListContainer');
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);

  return (
    <MainContent cover={true} image={siteData.mainCover} coverLink={siteData.mainCoverLink}>    
      <SEO 
        title="Главная"
        description="Магазин"
        ogUrl="https://sweetcatshop.ru/"
      />

      <div className={css.productList}>
        {categories.map((category: Types.Category) => (
          <div onClick={() => navigate(`/catalog/${category.id}`)}>
            <MiniCategory 
              title={category.name}
              description={category.miniDesc}
              image={category.miniCoverImg}
            />
          </div>
        ))}
      </div>
      
      {categories.map((category: Types.Category) => ( 
        products.filter((item: Types.Product) => item.category === category.id).length > 0 &&
          <>
            <h2 style={{ marginTop: 30 }}>{category.name}</h2>

            <div className={css.productList}>
              {products.map((product: Types.Product) => ( 
                product.category === category.id &&
                <MiniProduct 
                  id={product.id}
                  title={product.name}
                  price={product.price}
                  image={product.images[0]}
                />
              ))}

              {loading && <div>загружаем...</div>}
            </div>
          </>
      ))}
      <Email />
    </MainContent>
  )
}