import { useEffect, useState, FC } from 'react'
import { useNavigate } from "react-router-dom";
import css from './index.module.css';
import Content from '@components/adminNavigation';
import axios from "axios";
import * as Types from '../../../types';
import Popup from '@components/popup';
import SEO from '@components/seo';

export default function Categories() {
  const navigate = useNavigate();
  const [categories, setCatories] = useState<Types.Category[]>([]);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    axios.get(`${import.meta.env.VITE_API_ADM_URL}/categories`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      }
    }).then(response => {
        setCatories(response.data);
    }).catch(error => {
      console.log(error);
    });
  }, []);

  const handleRedirect = (id:number) => {
    navigate(`/manage/categories/${id}`);
  };

  return (
    <Content>
      <SEO 
        title={'Управление категориями'}
        description={'Админ-панель Sweet Cat Shop'}
        ogUrl={`https://sweetcatshop.ru/`}
      />

      <CreatePopup showPopup={showPopup} setShowPopup={setShowPopup} />
      <h1 className={css.head}>Список категорий <i className="fi fi-rr-add" style={{ float: 'right' }} onClick={() => setShowPopup(true)} /></h1>

      <div className={css.table}>
        <div className={css.tableHeader}>
          <a>Название</a>
          <a>Мини описание</a>
        </div>

        <div className={css.tableBody}>
          {categories.map((item) => (
            <div className={css.tableDiv} onClick={() => handleRedirect(item.id)}>
              <a>{item.name}</a>
              <a>{item.miniDesc}</a>
            </div>
          ))}
        </div>
    </div>
    </Content>
  );
}


interface PopupInfo {
  showPopup: boolean, 
  setShowPopup: any
}

const CreatePopup: FC<PopupInfo> = ({ showPopup, setShowPopup }) => {
  const navigate = useNavigate();

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    axios.post(`${import.meta.env.VITE_API_ADM_URL}/categories`, formData, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        }
    })
    .then(response => {
      navigate(`/manage/categories/${response.data.id}`)
    })
    .catch(error => {
      console.log(error);
    });
  };
  
  return (
  <Popup showPopup={showPopup} setShowPopup={setShowPopup}>
      <h2>Добавить категорию</h2>

      <form onSubmit={handleSubmit}>
        <div className={css.inputGrid}>
          <label>Название</label>
          <input name='name' type='text' placeholder='Название' />
        </div>

        <div className={css.inputGrid}>
          <label>Мини описание</label>
          <input name='miniDesc' type='text' placeholder='Мини описание' />
        </div>

        <button className={css.saveBtn} type='submit'>Добавить новую категорию</button>
      </form>
  </Popup>
  )
}