import { FC, useState } from "react";
import css from './index.module.css';
import * as Types from '../../types';
import ColorTabs from "@components/colorTabs";
import Tabs from "@components/tabs";

interface Section {
    id: number,
    name: string
}

interface Filters {
  category?: Types.Category;
  sections: Section[] | Types.Category[];
  products: Types.Product[] | undefined;
  activeSection?: number[];
  setActiveSection?: any;
  selectedFilters: any;
  setFilters: any;
}

interface FilterActive {
    products: Types.Product[] | undefined,
    showModal: boolean, 
    setShowModal: any;
    selectedFilters: any;
    setFilters: any;
}

const Filters:FC<Filters> = ({ category, sections, products, activeSection, setActiveSection, selectedFilters, setFilters }) => {
    const [showModal, setShowModal] = useState(false);

    const toggleSection = (id: number) => {
        setActiveSection((prevSections: any) => 
            prevSections.includes(id)
                ? prevSections.filter((sectionId: any) => sectionId !== id)
                : [...prevSections, id]
        );
    };

    return (
        <>
            <Filter products={products} showModal={showModal} setShowModal={setShowModal} selectedFilters={selectedFilters} setFilters={setFilters} />
            <div className={css.filters}>
                <div className={css.sections}>
                    {category &&
                        <div className={`${css.section} ${css.active}`} onClick={() => toggleSection(category.id)}>{category.name}</div>
                    }

                    {sections.map(section => (
                        <div className={`${css.section} ${activeSection?.includes(section.id) ? css.active : ''}`} onClick={() => toggleSection(section.id)}>{section.name}</div>
                    ))}
                </div>

                <div className={`${css.filterBtn} ${showModal ? css.active : ''}`} onClick={() => setShowModal(!showModal)}>фильтры</div>
            </div>
        </>
    );
};

const Filter:FC<FilterActive> = ({ showModal, products, setFilters }) => {
    const getUniqueSizesAndColors = (products: Types.Product[]) => {
        const uniqueSizes = new Map<string, { id: string, name: string }>();
        const uniqueColors = new Map<string, { id: string, color: string }>();
    
        products?.forEach(product => {
            product.sizes.forEach(size => {
                const sizeKey = `${size.name}`;
                if (!uniqueSizes.has(sizeKey)) {
                    uniqueSizes.set(sizeKey, { id: sizeKey, name: size.name });
                }
            });
    
            product.colors.forEach(color => {
                const colorKey = `${color.color}`;
                if (!uniqueColors.has(colorKey)) {
                    uniqueColors.set(colorKey, { id: colorKey, color: color.color });
                }
            });
        });
    
        return {
            sizes: Array.from(uniqueSizes.values()),
            colors: Array.from(uniqueColors.values()),
        };
    };

    const toggleFilterOption = (
        filterType: 'colors' | 'sizes' | 'sort', 
        value: string
    ) => {
        if (filterType === 'sort') {
            setFilters((prevFilters: any) => ({
                ...prevFilters,
                [filterType]: prevFilters[filterType] = value
            }));
            return;
        }

        setFilters((prevFilters: any) => ({
            ...prevFilters,
            [filterType]: prevFilters[filterType].includes(value)
                ? prevFilters[filterType].filter((item: any) => item !== value)
                : [...prevFilters[filterType], value],
        }));
    };

    const uniqueOptions = getUniqueSizesAndColors(products!);

    return (
        <div className={`${css.filtersPopup} ${showModal ? css.activeFilters : css.unActiveFilters}`}>
            <h2>фильтры</h2>
            
            <div className={css.filtersOptions}>
                <div className={css.filterOption}>
                    <label>сортировка</label>
                    <select name="sort" onChange={(e) => toggleFilterOption('sort', e.target.value)}>
                        <option value={0}>популярное</option>
                        <option value={1}>дешевле</option>
                        <option value={2}>дороже</option>
                    </select>
                </div>

                {uniqueOptions.colors?.length > 0 &&
                    <div className={css.filterOption}>
                        <label>цвет товара</label>
                        <ColorTabs 
                            sections={uniqueOptions.colors}
                            setActive={(id: string) => toggleFilterOption('colors', id)}
                        />     
                    </div>
                }

                {uniqueOptions.sizes?.length > 0 &&
                    <div className={css.filterOption}>
                        <label>размер товара</label>
                        <Tabs 
                            sections={uniqueOptions.sizes}
                            setActive={(id: string) => toggleFilterOption('sizes', id)}
                        />
                    </div>
                }
            </div>
        </div>
    )
}

export default Filters;