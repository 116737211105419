import { FC } from "react";
import { Link } from "react-router-dom";
import css from './index.module.css';
import * as Types from '../../types';
import useUserData from "@hooks/useUserData";
import Popup from "@components/popup";
import { toast } from "sonner";

interface PopupInfo {
  showPopup: boolean, 
  setShowPopup: any,
  selectedAddress: Types.Address | null,
  setSelectedAddress: any
}

const Addresses: FC<PopupInfo> = ({ showPopup, setShowPopup, selectedAddress, setSelectedAddress }) => {
  const { userData } = useUserData();

  const formatNumber = (num: number) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  };

  const selectAddress = (address: Types.Address) => {
    setShowPopup(false);
    setSelectedAddress(address);
    toast.success('Адрес выбран');
  }

  return (
    <Popup showPopup={showPopup} setShowPopup={setShowPopup}>
        <h2>выберите адрес</h2>
        
        <div className={css.addressList}>
          {userData.addresses?.map((item: Types.Address) => (
            <div className={`${css.address} ${selectedAddress && css.active}`} onClick={() => selectAddress(item)}>
              {item.type === 0 ? <i className="fi fi-rr-shop"></i> : <i className="fi fi-rr-marker"></i>}

              <div className={css.textInfo}>
                  <b>{item.address}</b>
                  <a>{item.type === 0 ? "заберете в магазине" : `доставим курьером за ${formatNumber(item.deliveryPrice)} ₽` }</a>
              </div>
            </div>
          ))}
        </div>

        <Link className={css.add} to={'/account'}>добавить новый адрес</Link>
    </Popup>
  )
}

export default Addresses;